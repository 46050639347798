import {createTheme} from "@mui/material/styles";
import {ThemeOptions} from "@mui/material/styles/createTheme";
import {primaryColor} from "./Colors";

export const defaultThemeOverrides: ThemeOptions = {
    components: {
        MuiDialog: {
            defaultProps: {
                fullWidth: true,
            },
        },
        MuiTextField: {
            defaultProps: {
                variant: "standard",
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                formControl: {
                    transform: "translate(0, 34px) scale(1)",
                    color: "hsla(0,0%,100%,.5)",
                },
                shrink: {
                    transform: "translate(0, 1.5px)",
                    color: "hsla(0,0%,100%,.5)",
                    top: "10px",
                },
            }
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    fontSize: "1.1rem",
                },
                input: {
                    fontSize: "1.1rem",
                    lineHeight: "1.3rem",
                    padding: "0.8rem 0",
                    height: "auto",
                    color: "white",
                },
                inputMultiline: {
                    borderBottom: "none",
                },
            }
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    fontSize: "1.1rem",
                    color: "hsla(0,0%,100%,.5)",
                },
            }
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    padding: "1.2rem 0",
                    width: "100%",
                },
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    fontSize: "1.1rem",
                    fontWeight: 500,
                    color: "#275584",
                    height: "36px",
                    minWidth: "9rem",
                    padding: "0 1.2rem",
                    width:"100%",
                },
            }
        },
    },

    typography: {
        fontFamily: "Roboto",
    },
    palette: {
        primary: primaryColor,
    },

};

const muiTheme = createTheme(defaultThemeOverrides);

export default muiTheme;

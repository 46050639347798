import {makeStyles} from "tss-react/mui";
import LoginBackground  from "../images/LoginBackground.png";
import RMlogo from "../images/RMLogo.png";
import inteliPhyNetLogo from "../images/InteliphyNetLogo.png";

export const useStyles = makeStyles()((theme) => {
    return {
        loginRoot: {
            position: "absolute",
            height: "100%",
            width:"100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "0",
            marginLeft: "0",
            marginRight: "0",
            marginBottom: "0",
            backgroundImage: `url(${LoginBackground})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition:"center",
        },

        imageContainer: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
        },

        rdmLogo: {

            height: "35px",
            width: "150px",
            marginBottom: "100px",
            background: `url(${RMlogo}) no-repeat center center`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            backgroundPosition:"center",
        },

        inteliPhyLogo: {
            width: "565px",
            height: "80px",
            background: `url(${inteliPhyNetLogo}) no-repeat center center`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",

        },

        loginFormWrapper: {
            width: "300px",
            padding: "30px",
        },

        loginBtn: {
            backgroundColor: "rgb(227,6,19) !important",
            color: "white !important",
        },

        label: {
            color: "rgba(255, 255 ,255, 0.5)",
            fontSize:" 1.3rem",

        },

        inputElement: {
            borderColor: "rgba(255, 255 ,255, 0.12) !important",
            color: "white",

        },
        buttonContainer: {
            paddingTop: "50px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
        },

        container: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
        },
    }
});

import {Autocomplete, Button, InputLabel, TextField} from "@mui/material";
import * as React from "react";
import MaterialUIWrapper from "../style/material-ui/MaterialUIWrapper";
import { v4 as uuidv4 } from 'uuid';
import Template from "../models/Template";
import {useStyles} from "./style";


let templates:Template[];
templates=[];
templates.push(new Template("L4778",48));
templates.push(new Template("L6113",48));
templates.push(new Template("L6140",40));
templates.push(new Template("L6145",40));
templates.push(new Template("L7871",189));
templates.push(new Template("L7872",80));
templates.push(new Template("L7873",48));
templates.push(new Template("L7911",48));

interface IMainFormProps {
    classes?: any;
}

class MainForm extends React.Component<IMainFormProps, any> {
    constructor(props: IMainFormProps) {
        super(props);
        this.state={
            numberOfSheets: "",
            inputValue: "",
            totalLabelsNumber: 0,
            data: '',
            selectedOption:{},
            averyBundleUrl: '',
            mergeData: '',
            chosenTemplate: null,
        }

        this.getNumberOfLabels=this.getNumberOfLabels.bind(this);
        this.generateData =this.generateData.bind(this);
        this.generateLabels =this.generateLabels.bind(this);
    }

    render() {
        const {classes} = this.props;

        return (

            <div className={classes.loginRoot}>
                <div className={classes.imageContainer}>
                    <div className={classes.container}>
                    <div className={classes.rdmLogo}/>
                    <div className={classes.inteliPhyLogo}/>
                    <div className={classes.loginFormWrapper}>
                        <MaterialUIWrapper>
                            <form action="https://dpp.avery-zweckform.com/dpp/public/v3/dpo/merge/direct?consumer=RDM&deploymentId=EU_en" acceptCharset="UTF-8" method="POST" target="_blank">
                                <Autocomplete
                                    id="autocomplete-template-number"
                                    options={templates}
                                    getOptionLabel={(option) => option.name + ' ('+ option.labelPerSheet+' per sheet)'}
                                    inputValue={this.state.inputValue}
                                    renderInput={(params) => (
                                            <TextField {...params}
                                                       label="Avery Template Number:"
                                                       fullWidth
                                                       variant={"standard"}
                                            />
                                        ) }
                                    onChange={(event,value)=>{this.handleAutocompleteChange(value)}}
                                    onInputChange={(event, value) => this.handleTemplateChange(value)}
                                />
                                <TextField
                                    id="textfield-labels-number"
                                    type="number"
                                    InputProps={{ inputProps: { min: 1, max: 40 } }}
                                    label="Number of sheets:"
                                    onChange={event => this.handleSheetNumberChange(event)}
                                    fullWidth
                                />

                                <InputLabel>{"Total number of labels: " + this.state.totalLabelsNumber}</InputLabel>

                                <div className={classes.buttonContainer}>
                                    <Button className={classes.loginBtn}
                                            data-testid={"login-button"}
                                            onClick={this.generateLabels}
                                            disabled={ !this.state.chosenTemplate || !this.state.numberOfSheets}
                                            type={"submit"}>
                                        { "Proceed"}

                                    </Button>
                                </div>

                                <input id= "mergeDataFormat" name="mergeDataFormat" value="json" hidden={true} readOnly={true}/>
                                <input id ="averyBundleUrl" name="averyBundleUrl" value={this.state.averyBundleUrl} hidden={true} readOnly={true}/>
                                <input id="mergeData" name="mergeData" value={this.state.mergeData} hidden={true} readOnly={true}/>
                            </form>
                        </MaterialUIWrapper>
                    </div>
                    </div>
                </div>
            </div>
        )
    }

    public getNumberOfLabels = () => {
        if (!this.state.chosenTemplate || !this.state.numberOfSheets ) {
            return 0;
        }
        const totalNumber = this.state.chosenTemplate.labelPerSheet * Number(this.state.numberOfSheets);
        this.setState({totalLabelsNumber: totalNumber});
        return totalNumber;
    }

    public  generateData = () => {

        const totalNumber = this.getNumberOfLabels();
        if(totalNumber!==0){
            let data='{"header":{"col":[{"value":"UUID"}]},"data":[';
            let i;
            for (i=0; i<totalNumber; i++) {
                const uuid = uuidv4();
                if (i === totalNumber-1){
                    data =  data + '{"col":[{"value":"' + uuid+ '"}]}';
                }
                else{
                    data =  data + '{"col":[{"value":"' + uuid+ '"}]},';
                }
            }
            data = data+']}';
            return data;
        }
        return '';

    }

    public generateLabels  =  () => {

        if (this.state.chosenTemplate && this.state.numberOfSheets) {
            const templateNumber = this.state.chosenTemplate.name;
            const averyBundleUrl= 'https://assets.inteliphy-net.rdm.com/avery/'+templateNumber+'.avery'
            const data = this.generateData();

            this.setState({averyBundleUrl:averyBundleUrl, mergeData:data});
        }
    }

    private handleTemplateChange(value: string) {
        this.setState({inputValue:value},()=> {
            this.generateLabels();
        });

    }

    private handleSheetNumberChange(event: any) {
        event.preventDefault();
        this.setState({numberOfSheets: event.target.value}, ()=>{
            this.generateLabels();
        })
    }

    private handleAutocompleteChange(value:any) {
        this.setState({chosenTemplate: value},()=> {
            this.generateLabels();
        })
    }
}

const Main = (props: IMainFormProps) => {
    const { classes } = useStyles();
    return (
        <MainForm {...props} classes={classes}/>
    )
}

export default Main;

import {Theme, ThemeProvider} from "@mui/material/styles";
import * as React from "react";

import muiTheme from "./muiTheme";

export interface IMaterialUIWrapperProps {
    children: React.ReactNode;
    theme?: Theme;
}

class MaterialUIWrapper extends React.Component<IMaterialUIWrapperProps> {


    private readonly theme: Theme;

    constructor(props: IMaterialUIWrapperProps) {
        super(props);
        this.theme = props.theme ? props.theme : muiTheme;
    }

    public render() {
        return <ThemeProvider theme={this.theme}>
            {this.props.children}
        </ThemeProvider>;
    }
}

export default MaterialUIWrapper;

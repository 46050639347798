export default class Template {

    public name: string = "";

    public labelPerSheet: number = 0;

    constructor(name:string, labelPerSheet:number) {
        this.name=name;
        this.labelPerSheet=labelPerSheet;
    }
}